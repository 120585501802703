@import "../lib/mediaquery";
@import "../lib/variable";

.section-contact-page {
  padding: 120px 0;

  .container {
    margin-top: 60px;

    @include pctab {
      max-width: 874px;
    }

    @include sp {
      width: 95.73%;
    }
  }

  .step {
    margin-bottom: 36px;

    &__item {
      color: #aeb5b3;
      font-size: 18px;

      &:nth-child(1n+2) {
        margin-left: 56px;
      }

      &--active {
        color: #42c2b3;
      }

      small {
        font-size: 16px;
      }
    }
  }
}

.contact-form {
  &__container {
    border-radius: 12px;
    box-shadow: 0px 0px 60px rgb(0 0 0 / 10%);
    background-color: #fff;

    @include pctab {
      padding: 32px 40px 56px 40px;
    }

    @include sptab {
      padding: 34px 24px 110px 24px;
    }
  }

  &__wrap {
    display: block;
  }

  &__note {
    margin-bottom: 40px;
    font-size: 13px;

    span {
      color: #42c2b3;
    }
  }

  .form-label {
    font-size: 18px;

    span {
      color: #42c2b3;
    }
  }

  .form-control {
    background-color: #f2f2f2;
    border-color: #eaeaea;

    &::placeholder {
      color: #cfd3d2;
    }
  }

  .form-select {
    background-color: #f2f2f2;
    border-color: #eaeaea;
  }

  .button {
    margin-top: 56px;

    max-width: 100%;
  }

  &__submit-note {
    text-align: center;
    // color: #aaa;
    color: #585858;
    font-size: 11px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .form-check-label {
    font-size: 14px;

    a {
      color: #42c2b3;
    }
  }
}

@import "../lib/mediaquery";
@import "../lib/variable";

.section-policy {
  padding: 120px 0 95px 0;

  .container {
    @include pctab {
      max-width: 874px;
      margin-top: 80px;
    }

    @include sp {
      width: 95.73%;
      margin-top: 80px;
    }
  }

  &__title {
    font-size: 18px;
    margin-bottom: 36px;
  }

  li {
    margin-bottom: 10px;
  }
}


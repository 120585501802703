.sp {
  @include media-breakpoint-up(sm) {
    display: none !important;
  }
}

.pc {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.tab {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }

  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

.pctab {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.sptab {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}



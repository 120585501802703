@import "../lib/mediaquery";
@import "../lib/variable";

.header {
  &--front-page {
    background-color: #f2f2f2;
  }

  &--static {
    @include pc {
      position: relative;
      z-index: 1031;
    }
  }

  .navbar {
    .navbar-brand {
      padding: 0;
    }

    > .container-fluid {
      @include pctab {
        padding: 0px 32px 0px 40px;
      }

      @include sp {
        padding: 0px 8px 0px 20px;
      }
    }

    .navbar-collapse {
      flex-grow: inherit;
    }
  }

  .button {
    @include pc {
      width: 192px;
      height: 40px;
      background-color: #fff;
      border: 1px solid #42C2B3;
    }

    @include sptab {
      max-width: 240px;
      height: 48px;
      font-size: 16px;
    }

    .phone-text {
      text-decoration: none;

      @include pc {
        color: #42c2b3;
        padding-left: 28px;
        font-size: 16px;
        background-image: url("../assets/pages/home/icon-phone-green.svg");
      }

      @include sptab {
        font-size: 18px;
        background-size: 24px auto;
        background-image: url("../assets/pages/home/icon-phone-blue.svg");
      }

      small {
        @include pc {
          font-size: 10px;
        }

        @include sptab {
          font-size: 12px;
        }
      }
    }
  }

  .fixed-toggle {
    position: fixed;
    z-index: 1056;
    top: 8px;

    @include tab {
      right: 32px;
    }

    @include sp {
      right: 8px;
    }
  }
}

.navbar-toggler {
  @include sptab {
    width: 44px;
    height: 44px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    background-color: #fff;
    border: 1px solid #42C2B3;
    z-index: 1056;
  }

  &:focus {
    box-shadow: none;
  }

  &.active .menu-trigger span {
    &:nth-child(1) {
      transform: translateY(4px) rotate(-45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: translateY(-4px) rotate(45deg);
    }
  }
}

.navbar-nav  {
  .nav-link {
    text-align: center;
    color: #000;
    white-space: nowrap;
    letter-spacing: 0;
    padding-top: 0;
    padding-bottom: 0;

    span {
      display: block;

      &.en {
        @extend %lato;
        font-size: 14px;
        font-weight: 700;
        font-weight: 900;
      }

      &.ja {
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
}

.column-sns,
.column-contact {
  .row {
    margin-top: 0;

    > * {
      margin-top: 0;
    }
  }
}

.menu-trigger {
  position: relative;
  width: 18px;
  height: 10px;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  &,
  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
  }

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #42C2B3;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 4px;
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }


}

#mobileNav {
  width: 85.33%;
  left: inherit;
  right: 0;

  .modal-dialog {
    width: 100%;
    transform: translate(100%, 0);
  }

  .modal-body {
    display: flex;
    align-items: center;

    .navbar-nav {
      .nav-item:nth-child(1n+2) {
        margin-top: 17px;
      }
    }

    .row {
      margin-top: 28px;

      .col {
        padding-left: 0;
        padding-right: 0;

        &:nth-child(1n+2) {
          margin-top: 8px;
        }
      }
    }
  }

  &.show {
    .modal-dialog {
      transform: none;
    }
  }
}







@import "../lib/mediaquery";
@import "../lib/variable";

.footer {
  background-color: #f3f3f3;

  &__bg {
    overflow: hidden;
    background-color: #fff;
    width: 100%;

    img {
      display: block;

      @include pc {
        width: 100%;
      }

      @include tab {
        width: 163.78%;
      }

      @include sp {
        width: 364.26%;
      }
    }
  }

  .container {
    @include pctab {
      margin-top: 48px;
      max-width: 764px;
    }

    @include sp {
      
    }
  }

  .animation-img {
    display: block;
    width: 100%;

    @include pctab {
      margin-top: 92px;
    }

    @include sp {
      margin-top: 32px;
    }
  }

  .navbar-collapse {
    @include tab {
      margin-top: 32px;
    }

    @include sp {
      max-width: 180px;
      margin-top: 40px;
    }

    .navbar-nav  {
      @include sp {
        flex-wrap: wrap;
        flex-direction: row;
      }

      .nav-item {
        @include sp {
          width: 50%;
        }

        &:nth-child(1n+3) {
          @include sp {
            margin-top: 18px;
          }
        }
      }
    }
  }

  &__logo {
    display: block;

    @include sptab {
      margin: 0 auto;
      width: 160px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__sub-nav {
    display: flex;

    @include pc {
      justify-content: flex-end;
    }

    @include sptab {
      justify-content: center;
    }

    @include pctab {
      align-items: center;
    }

    @include sp {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      margin-top: 32px;
    }

    .phone-text {
      background-image: url("../assets/pages/home/icon-phone-black.svg");
      background-size: 18px auto;
      padding-left: 26px;
      text-decoration: none;
      color: #000;
      font-size: 16px;

      small {
        font-size: 10px;
      }
    }
  }

  &__privacy-link {
    font-size: 11px;
    letter-spacing: 0;

    @include sp {
      width: 100%;
      text-align: center;
    }

    a {
      text-decoration: none;
      color: #000;
    }
  }

  .column-sns {
    @include sp {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 30px 0;
    }
  }

  &__copyright {
    margin-top: -1px;
    background-color: #000;
    color: #fff;
    font-size: 11px;
    padding: 18px 0;
  }
}




@import "../lib/mediaquery";
@import "../lib/variable";

.section-fv {
  overflow: hidden;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/pages/home/fv-pc.jpg");

  @include pc {
    height: calc(100vh - 56px);
  }

  @include sptab {
    height: calc(100vh - 60px);
  }

  &__title-area {
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;

    @include pctab {
      top: 50%;
    }

    @include sp {
      top: 44%;
    }
  }

  &__pop {
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% - 56px);
    right: 78px;
  }

  &__typography {
    display: flex;

    @include pc {
      margin-bottom: 40px;
      align-items: center;
      justify-content: center;
    }

    @include sptab {
      margin-bottom: 22px;
      align-items: flex-start;
      flex-direction: column;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(1n+2) {
        @include pc {
          margin-left: 22px;
        }

        @include sptab {
          margin-top: 18px;
        }
      }
    }

    .letter {
      &:nth-child(1n+2) {
        @include pc {
          margin-left: 22px;
        }

        @include sptab {
          margin-left: 18px;
        }
      }
    }
  }

  &__animation {
    position: absolute;
    bottom: 0;

    @include pc {
      width: 100%;
      left: 0;
    }

    @include tab {
      width: 163.78%;
    }

    @include sp {
      width: 266.66%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__title {
    @include pc {
      text-align: center;
      font-size: 32px;
    }

    @include sptab {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  &__text {
    @include pc {
      text-align: center;
      font-size: 20px;
    }

    @include sptab {
      font-size: 12px;
    }
  }
}

.section-loading {
  position: fixed;
  z-index: 2000;
  background-color: #42c2b3;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &.loading-active {

    .loading-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include pc {
    padding-top: 56px;
  }

  @include sptab {
    padding-top: 60px;
  }

  .loading-animation {
    transition: all 0.2s linear;
    transform: translateY(20%);
    opacity: 0;
  }

  .container-fluid {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__typography {
    display: flex;

    @include pc {
      margin-bottom: 40px;
      align-items: center;
      justify-content: center;
    }

    @include sptab {
      margin-bottom: 22px;
      align-items: flex-start;
      flex-direction: column;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(1n+2) {
        @include pc {
          margin-left: 22px;
        }

        @include sptab {
          margin-top: 18px;
        }
      }

      &:nth-child(1) .letter {
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: #{$i * 0.1}s;
          }
        }
      }

      &:nth-child(2) .letter {
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            transition-delay: #{$i * 0.1 + 0.4}s;
          }
        }
      }
    }

    .letter {
      &:nth-child(1n+2) {
        @include pc {
          margin-left: 22px;
        }

        @include sptab {
          margin-left: 18px;
        }
      }
    }
  }

  &__title {
    transition-delay: 0.8s;

    @include pc {
      text-align: center;
      font-size: 32px;
    }

    @include sptab {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  &__text {
    transition-delay: 1.5s;

    @include pc {
      text-align: center;
      font-size: 20px;
    }

    @include sptab {
      font-size: 12px;
    }
  }

  &__title-area {
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;

    @include pctab {
      top: 50%;
    }

    @include sp {
      top: 44%;
    }
  }
}

.section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  line-height: 1;

  small {
    @extend %lato;
    color: #42c2b3;
    font-size: 16px;
    font-weight: 700;
  }

  span {
    font-weight: 400;
    font-size: 44px;
    margin-top: 10px;
    text-align: center;
    line-height: 1.2;
  }
}

.section-charm {
  background-color: #42c2b3;
  padding: 50px 0 38px 0;

  @include sp {
    padding: 56px 0;
  }

  .container {
    @include pc {
      max-width: 1126px;
    }

    @include sp {
      width: 86.93%;
    }
  }

  &__title {
    @include pc {
      margin-right: 50px;
    }

    @include sp {
      margin-bottom: 16px;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .tag-content {
    justify-content: flex-start;

    > div {
      color: #fff;
      border-color: #fff;
    }
  }
}



.section-about {
  padding-top: 120px;
  background-repeat: no-repeat;
  background-position: left 0 bottom 0;
  background-size: contain;
  background-image: url("../assets/pages/home/about-bg.svg");

  @include pc {
    padding-bottom: 196px;
  }

  @include tab {
    padding-bottom: 142px;
  }

  @include sp {
    padding-bottom: 74px;
  }


  .container {
    @include pctab {
      max-width: 920px;
      margin-top: 128px;
    }

    @include sp {
      width: 95.73%;
      margin-top: 56px;
    }

    > .row {
      align-items: center;

      @include tab {
        max-width: 484px;
        margin-left: auto;
        margin-right: auto;
      }

      @include sptab {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .col-12 {
        @include sptab {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &__typography {
    @include pctab {
      font-size: 56px;
    }

    @include sp {
      font-size: 36px;
    }

    img {
      display: block;

      &:nth-child(1n+2) {
        margin-top: 34px;
      }
    }
  }

  &__text {
    font-size: 18px;

    @include sp {
      margin-top: 1.5em;
    }

    &:first-child {
      @include tab {
        margin-top: 78px;
      }
    }

    span {
      color: #42c2b3;
    }
  }
}

.section-about-b {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include pctab {
    background-image: url("../assets/pages/home/about-b-bg.svg");
    max-width: 674px;
    min-height: 452px;
    margin: 136px auto 0 auto;
    padding: 83px 87px 0 87px;
  }

  @include tab {
    margin-top: 124px;
    min-height: 452px;
  }

  @include sp {
    min-height: 594px;
    background-image: url("../assets/pages/home/about-b-bg-sp.svg");
    padding: 54px 28px 45px 28px;
    margin-top: 44px;
  }

  &__title {
    color: #42c2b3;
    font-size: 24px;
    margin-bottom: 22px;
  }

  &__subtitle {
    margin-bottom: 22px;
    font-size: 16px;
  }

  &__text {
    @extend %GothicMediumBBB;
    margin-bottom: 14px;
    font-size: 14px;
  }

  &__note {
    font-size: 11px;
    color: #aeb5b3;
  }
}

.section-problem {
  padding-bottom: 64px;

  @include tab {
    padding-bottom: 120px;
  }

  .container {
    @include pctab {
      max-width: 1120px;
      margin-top: 74px;
    }

    @include sp {
      margin-top: 36px;
      width: 100%;
    }
  }

  &__content-wrap {
    background-color: #f2f2f2;

    @include pctab {
      padding: 120px 0;
    }
    @include sp {
      padding: 120px 0 80px 0;
    }
  }

  &__pop {
    @include pctab {
      justify-content: center;
    }

    @include tab {
      max-width: 715px;
      margin: 0 auto;
    }

    @include sp {
      max-width: 335px;
      margin: 0 auto;
      justify-content: space-between;
    }

    .col {
      padding: 0;

      @include tab {
        padding-top: 20px;
      }

      @include pc {
        margin: 0 -18px;
      }

      &:nth-child(2n+4) {
        padding-right: 8px;

        .problem-pop {
          @include tab {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }

      &:nth-child(2n+5) {
        padding-left: 8px;

        .problem-pop {
          @include tab {
            margin-right: auto;
            margin-left: 0;
          }
        }
      }


      &:nth-child(odd) {
        .problem-pop {
          @include sp {
            right: -10px;
          }
        }
      }

      &:nth-child(even) {
        .problem-pop {
          @include sp {
            left: -10px;
            top: 60px;
          }
        }
      }

      // &:nth-child(1n+3) {
      //   @include sp {
      //     margin-top: -30px;
      //   }
      // }
    }

    .problem-pop {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../assets/pages/home/problem-pop.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));

      @include pctab {
        height: 192px;
        padding: 0 0 10px 0;
      }

      @include tab {
        width: 230px;
      }

      @include sp {
        height: 160px;
      }
    }

    span {
      letter-spacing: 0;
      line-height: 1.5;

      @include pctab {
        font-size: 18px;
      }

      @include sp {
        font-size: 14px;
      }

      &.green {
        color: #42c2b3;
      }

      &.blue {
        color: #0f4984;
      }
    }
  }

  &__course {
    background: linear-gradient(180deg, rgba(242,242,242,1) 50%, rgba(255,255,255,1) 50%);

    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;

      @include pc {
        max-width: 1000px;
      }

      @include tab {
        width: 83.93%;
      }

      @include sp {
        width: 100%;
      }
    }
  }
}

.section-speciality {
  overflow: hidden;

  .section-title {
    @include pctab {
      margin-bottom: 90px;
    }

    @include sp {
      margin-bottom: 56px;
    }
  }

  .speciality-column {
    @include sp {
      display: block;
    }

    &__content-wrap {
      background-repeat: no-repeat;
      align-items: flex-end;

      @include pctab {
        padding-top: 84px;
      }

      @include tab {
        padding-left: 56px;
        background-size: contain;
      }

      @include sptab {
        background-position: 0 0;
      }

      @include sp {
        padding-top: 45px;
      }

      &--point {
        background-image: url("../assets/pages/home/speciality-typography-point.svg");

        @include pc {
          background-position: 90% 0;
          justify-content: flex-end;
          padding-right: 80px;
        }

        @include tab {
          margin-right: -168px;
          padding-bottom: 80px;
          width: calc(50% + 168px);
        }

        @include sp {
          background-size: 302px auto;
          background-position: top 0 left -10px;
        }
      }

      &--course {
        background-image: url("../assets/pages/home/speciality-typography-course.svg");

        @include pc {
          padding-left: 80px;
          justify-content: flex-start;
        }

        @include tab {
          margin-right: -295px;
          padding-bottom: 100px;
          width: calc(50% + 295px);
        }

        @include sp {
          background-size: 390px auto;
          background-position: top 0 right 0;
        }
      }
    }

    &__title {
      font-size: 32px;

      @include sp {
        margin-bottom: 16px;
      }
    }

    &__text {
      font-size: 18px;
    }
  }

  .speciality-point {
    max-width: 878px;

    @include pctab {
      margin-top: 80px;
      margin-bottom: 150px;

    }

    @include sp {
      margin-top: 56px;
    }

    .col {
      @include sp {
        margin: 0 auto;
      }

      &:nth-child(1n+2) {
        @include sptab {
          margin-top: 42px;
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 24px;

      &--green {
        color: #42c2b3;
      }

      &--blue {
        color: #0f4984;
      }

      &--pink {
        color: #fcbdce;
      }

      small {
        font-size: 16px;
      }

      span {
        margin-top: 8px;
        font-weight: normal;
      }
    }

    &__list {
      padding-left: 0;
      list-style: none;
      margin-top: 32px;

      @include tab {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      &--green {
        li {
          background-color: #42c2b3;

          &::after {
            background-image: url("../assets/pages/home/speciality-point-list-green.svg");
          }
        }
      }

      &--blue {
        li {
          background-color: #0f4984;

          &::after {
            background-image: url("../assets/pages/home/speciality-point-list-blue.svg");
          }
        }
      }

      &--pink {
        li {
          background-color: #fcbdce;

          &::after {
            background-image: url("../assets/pages/home/speciality-point-list-pink.svg");
          }
        }
      }

      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 88px;
        padding: 20px 25px;
        font-size: 16px;
        letter-spacing: 0;
        color: #fff;

        @include tab {
          width: calc(50% - 16px);
          margin: 8px;
        }

        &:nth-child(1n+2) {
          @include pc {
            margin-top: 8px;
          }

          @include sp {
            margin-top: 8px;
          }
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 24px;
          height: 20px;
          background-color: #fff;
          background-size: contain;
        }
      }
    }
  }

  .speciality-customize {
    @include pc {
      padding-top: 158px;
      padding-bottom: 100px;
      max-width: 1048px;
      margin-top: 80px;
    }

    @include tab {
      padding-top: 64px;
      margin-top: 32px;
      padding-bottom: 90px;
    }

    @include sp {
      width: 95.73%;
      margin-top: 35px;
      padding: 124px 0 112px 0;
    }

    &__content {
      @include tab {
        margin-left: 26px;
      }
    }

    &__row {
      position: relative;
      justify-content: center;
      margin: 0;

      @include pc {
        padding: 40px;
      }

      @include tab {
        max-width: 540px;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 50px;
      }

      @include sp {
        padding: 24px 24px 42px 24px;
      }

      &:nth-child(1n+2) {
        @include pctab {
          margin-top: 58px;
        }

        @include sp {
          margin-top: 80px;
        }
      }

      &:nth-child(odd) {
        .speciality-customize__pop {
          @include pctab {
            left: -88px;
          }

          @include sp {
            left: -28px;
          }
        }
      }

      &:nth-child(even) {
        .speciality-customize__pop {
          @include pctab {
            right: -88px;
          }

          @include sp {
            right: -28px;
          }
        }
      }

      &--green {
        background-color: #42c2b3;
      }

      &--blue {
        background-color: #0f4984;
      }

      &--pink {
        background-color: #FCBDCE;
      }

      &.row-5 {
        .col {
          @include pc {
            width: calc(100% / 5);
          }

          @include tab {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
            padding-left: 0;
            padding-right: 0;
          }

          &:nth-child(1n+2) {
            @include tab {
              padding-top: 25px;
              border-top: 1px dashed #fff;
            }
          }

          &:nth-child(2n+2) {
            @include tab {
              border-left: none;
            }
          }
        }

        .speciality-customize__icon {
          @include tab {
            width: 130px;
            flex-shrink: 0;
          }
        }
      }

      .col {
        text-align: center;
        color: #fff;

        @include pctab {
          margin-top: 0;
        }

        @include tab {
          padding: 24px 20px;
        }

        &:first-child {
          @include pc {
            padding-left: 0;
          }
        }

        &:last-child {
          @include pc {
            padding-right: 0;
          }
        }

        &:nth-child(1n+2) {
          @include pc {
            border-left: 1px dashed #fff;
          }

          @include sp {
            padding-top: 25px;
            margin-top: 25px;
            border-top: 1px dashed #fff;
          }
        }

        &:nth-child(2n+2) {
          @include tab {
            border-left: 1px dashed #fff;
          }
        }

        &:nth-child(1n+3) {
          @include tab {
            border-top: 1px dashed #fff;
          }
        }
      }
    }

    &__pop {
      position: absolute;
      filter: drop-shadow(0 0 50px rgba(0, 0, 0, 0.5));

      @include pc {
        width: 170px;
        top: -102px;
      }

      @include sptab {
        width: 177px;
        top: -64px;
      }

      @include sp {
        top: -102px;
      }
    }

    &__icon {
      background-repeat: no-repeat;
      background-position: top 0 center;
      height: 106px;

      &--01 {
        background-image: url("../assets/pages/home/speciality-customize-icon-01.svg");
      }

      &--02 {
        background-image: url("../assets/pages/home/speciality-customize-icon-02.svg");
      }

      &--03 {
        background-image: url("../assets/pages/home/speciality-customize-icon-03.svg");
      }

      &--04 {
        background-image: url("../assets/pages/home/speciality-customize-icon-04.svg");
      }

      &--05 {
        background-image: url("../assets/pages/home/speciality-customize-icon-05.svg");
      }

      &--06 {
        background-image: url("../assets/pages/home/speciality-customize-icon-06.svg");
      }

      &--07 {
        background-image: url("../assets/pages/home/speciality-customize-icon-07.svg");
      }

      &--08 {
        background-image: url("../assets/pages/home/speciality-customize-icon-08.svg");
      }

      &--09 {
        background-image: url("../assets/pages/home/speciality-customize-icon-09.svg");
      }

      &--10 {
        background-image: url("../assets/pages/home/speciality-customize-icon-10.svg");
      }

      &--11 {
        background-image: url("../assets/pages/home/speciality-customize-icon-11.svg");
      }
    }

    &__title {
      font-size: 18px;

      @include pc {
        letter-spacing: 0.05em;
      }
    }

    &__text {
      @extend %GothicMediumBBB;
      font-size: 14px;
      margin: 14px 0 0 0;
      letter-spacing: 0;
    }

    &__after-title {
      margin-top: 80px;
    }
  }
}

.tag-content {
  line-height: 1.3;

  @include pctab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    letter-spacing: 0.05em;
  }

  @include sptab {
    font-size: 14px;
  }

  @include tab {
    letter-spacing: 0;
  }

  @include sp {
    text-align: center;
  }

  > div {
    @extend %GothicMediumBBB;
    border: 1px solid #000;

    @include pctab {
      margin: 0 4px 4px 4px;
      padding: 6px 14px;
    }

    @include sp {
      padding: 10px;
    }

    &:nth-child(1n+2) {
      @include sp {
        margin-top: 8px;
      }
    }

    span {
      @extend %MidashiGoMB31;
      font-weight: 700;
    }
  }
}

.section-subtitle {
  text-align: center;
  font-weight: 500;

  @include pctab {
    font-size: 32px;
  }

  @include sp {
    font-size: 24px;
  }
}

.section-contact {
  @include sp {
    overflow: hidden;
  }

  &__bg {
    display: block;

    @include pctab {
      width: 100%;
    }

    @include sp {
      min-width: 100%;
      height: 194px;
    }
  }

  .container-fluid {
    background-color: #42c2b3;

    @include pctab {
      padding-bottom: 100px;
    }

    @include sp {
      margin: -50px auto 0 auto;
      padding: 0 5.335% 120px 5.335%;
    }
  }

  &__title {
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 44px;
  }

  &__text {
    color: #fff;
    line-height: 1.8;

    @include pctab {
      font-size: 16px;
      margin-top: 32px;
      text-align: center;
    }

    @include sp {
      text-align: left;
      margin-top: 22px;
      font-size: 18px;
    }
  }

  &__btn {
    margin-left: auto;
    margin-right: auto;

    @include pctab {
      margin-top: 56px;
      max-width: 972px;
      display: flex;
      justify-content: center;
    }

    @include sp {
      margin-top: 50px;
    }

    .col {
      &:nth-child(1n+2) {
        @include sptab {
          margin-top: 16px;
        }
      }
    }
  }

  .button--phone {
    .phone-text {
      background-image: url("../assets/pages/home/icon-phone-blue.svg");
    }

    &:hover {
      .phone-text {
        background-image: url("../assets/pages/home/icon-phone-white.svg");
      }
    }
  }
}

.phone-text {
  font-size: 24px;
  font-weight: 700;
  background-position: left 0 center;
  background-repeat: no-repeat;
  padding-left: 34px;
  line-height: 1;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  small {
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 300px;
  height: 64px;
  border-radius: 32px;
  text-align: center;
  color: #fff;
  background-color: #0f4984;
  text-decoration: none;
  font-size: 20px;
  line-height: 1.2;
  transition: all 0.2s ease-in-out;
  border: 2px solid #0f4984;

  &:hover {
    color: #0f4984;
    background-color: #fff;
  }

  &--phone {
    color: #0f4984;
    background-color: #fff;

    &:hover {
      color: #fff;
      background-color: #0f4984;
    }
  }

  &--email {
    span {
      padding-left: 40px;
      background-image: url("../assets/pages/home/icon-email-white.svg");
    }

    &:hover span {
      background-image: url("../assets/pages/home/icon-email-blue.svg");
    }
  }

  &--line {
    span {
      min-height: 31px;
      padding-left: 48px;
      background-size: auto 30px;
      background-image: url("../assets/pages/home/icon-line-white.svg");
    }

    &:hover span {
      background-image: url("../assets/pages/home/icon-line-blue.svg");
    }
  }

  &--green {
    background-color: #42c2b3;
    border-color: #42c2b3;

    &:hover {
      color: #42c2b3;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: left 0 center;
    transition: all 0.2s ease-in-out;
  }
}

.section-voice {
  padding: 120px 0;
  background-color: #f2f2f2;

  .container {
    @include pctab {
      margin-top: 74px;
    }

    @include pc {
      max-width: 1048px;
    }

    @include tab {
      width: 87.52%;
    }

    @include sp {
      width: 95.73%;
      margin-top: 74px;
    }
  }

  .user-voice-slider {
    @include pc {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    @include tab {
      margin-right: calc(-6.24vw + -12px);
    }

    @include sp {
      margin-right: calc(-2.135vw + -12px);
    }

    &__item {
      background-color: #fff;
      border-radius: 10px;

      @include pc {
        width: 48.82%;
        padding: 40px 34px;
      }


      @include sptab {
        padding: 40px 24px;
      }

      &:nth-child(2n+2) {
        @include pc {
          margin-left: 2.36%;
        }
      }

      &:nth-child(1n+3) {
        @include pc {
          margin-top: 2.36%;
        }
      }

      .row {
        @include sptab {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    &__icon {
      display: block;

      @include pctab {
        width: 100%;
      }

      @include sp {
        width: 160px;
        margin: 0 auto;
      }
    }

    &__profile {
      @extend %GothicMediumBBB;
      font-size: 14px;
      margin-top: 15px;

      dt,
      dd {
        padding-top: 0.5rem;

        &:nth-of-type(1n+2) {
          border-top: 1px solid #CFD3D2;
        }
      }

      dt {
        @include tab {
          padding-left: 0;
        }
      }

      dd {
        position: relative;

        @include tab {
          padding-right: 0;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: calc(100% - 0.5rem);
          left: 0;
          top: 0.5rem;
          background-color: #000;
        }
      }
    }

    &__title {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__text {
      @extend %GothicMediumBBB;
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  .section-subtitle {
    @include pctab {
      margin-top: 68px;
    }

    @include sp {
      margin-top: 80px;
    }
  }

  .tag-content {
    @include sp {
      width: 83.6%;
      margin-left: auto;
      margin-right: auto;
    }
  }


}

.section-flow {
  padding: 120px 0;

  .container {
    @include pc {
      margin-top: 62px;
      max-width: 874px;
    }

    @include tab {
      margin-top: 56px;
    }

    @include sp {
      margin-top: 56px;
      width: 95.73%;
    }
  }

  .tag-content {
    @include sp {
      width: 83.6%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .row {
    @include pctab {
      margin-top: 54px;
    }

    @include tab {
      max-width: 424px;
      margin-left: auto;
      margin-right: auto;
    }

    @include sp {
      margin-top: 70px;
    }

    .col {
      &:nth-child(1n+2) {
        @include sptab {
          margin-top: 24px;
        }
      }
    }
  }

  &__card {
    border-radius: 20px;
    overflow: hidden;
    border: none;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);

    .card-img-top {
      border-radius: 0;
    }

    .card-body {
      @include pc {
        min-height: 224px;
      }

      @include pctab {
        padding: 20px 20px 32px 20px;
      }

      @include sp {
        padding: 16px 16px 32px 16px;
      }
    }

    .card-num {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      color: #42c2b3;
    }

    .card-title {
      font-size: 18px;
    }

    .card-text {
      @extend %GothicMediumBBB;
      font-size: 14px;
      letter-spacing: 0.1em;
    }
  }
}

.section-faq {
  background-color: #f2f2f2;
  padding-top: 120px;

  .container {
    @include pctab {
      margin-top: 50px;
      margin-bottom: 100px;
    }

    @include pc {
      max-width: 874px;
    }

    @include tab {
      max-width: 579px;

    }

    @include sp {
      margin-top: 52px;
      max-width: 95.73%;
    }
  }

  .faq-list {
    list-style: none;
    padding-left: 0;
    margin: 0;

    &__item {
      &:nth-child(1n+2) {
        @include pctab {
          margin-top: 16px;
        }

        @include sp {
          margin-top: 13px;
        }
      }
    }
  }

  .section-contact {
    @include sp {
      margin-top: 120px;
    }
  }
}

.faq-modal-btn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  border: none;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.2s linear;

  @include pctab {
    padding: 18px 24px 18px 32px;
  }

  @include sp {
    padding: 18px 10px;
  }

  &:hover {
    padding-left: 50px;
    background-color: #42c2b3;
  }

  &:hover & {
    &__q,
    &__title {
      color: #fff;
    }

    &__icon {
      background-color: #fff;
      color: #42c2b3;
    }
  }

  &__q {
    color: #42c2b3;
    font-size: 24px;
    transition: all 0.2s linear;
  }

  &__title {
    font-size: 18px;
    margin-left: 10px;
    transition: all 0.2s linear;
    text-align: left;
  }

  &__icon {
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #42c2b3;
    margin-left: auto;
    transition: all 0.2s linear;
    flex-shrink: 0;
  }
}

.faq-modal {
  .modal-content {
    border-radius: 10px;

    @include pctab {
      padding: 56px;
    }

    @include sp {
      padding: 24px;
    }
  }

  .modal-dialog {
    max-width: 600px;
  }

  &__title {
    font-size: 24px;

    @include sp {
      text-align: center;
      margin-bottom: 10px;
      line-height: 1.4;
    }

    span {
      color: #42c2b3;

      @include pctab {
        display: inline-block;
        margin-right: 10px;
      }

      @include sp {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.8;
    margin: 24px 0 0 0;
  }
}

.section-access {
  padding: 120px 0;

  .container {
    @include pctab {
      margin-top: 50px;
    }

    @include pc {
      max-width: 1048px;
    }

    @include tab {
      max-width: 524px;
    }

    @include sp {
      width: 95.73%;
      margin-top: 56px;
    }
  }

  &__map {
    display: block;
    width: 100%;

    @include pctab {
      height: 270px;
    }

    @include tab {
      margin-bottom: 36px;
    }

    @include sp {
      height: 270px;
      margin-bottom: 32px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
  }

  &__text {
    @extend %GothicMediumBBB;
    font-size: 14px;
    letter-spacing: 0;
  }


  &__imaage {
    margin-top: 56px;

    .col {
      &:nth-child(1n+2) {
        @include sptab {
          margin-top: 16px;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  &__pop {
    position: relative;

    @include sp {
      margin-top: 26px;
    }

    img {
      @include pctab {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      @include sp {
        display: block;
        margin: 0 0 0 auto;
      }
    }
  }
}

.fixed-area {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 240px;
    border-width: 2px 0 2px 2px;
    border-style: solid;
    border-color: #fff;
    color: #fff;
    background-color: #0f4984;
    font-size: 16px;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    writing-mode: vertical-rl;
    text-decoration: none;
    text-align: center;
    border-radius: 20px 0 0 20px;
    font-feature-settings: initial;

    &:nth-child(1n+2) {
      margin-top: 12px;
    }

    > span {
      background-repeat: no-repeat;
      background-position: top 0 left 0;
      padding-top: 27px;

      &.contact {
        background-image: url("../assets/pages/home/icon-contact-white.svg");
      }

      &.line {
        background-image: url("../assets/pages/home/icon-line-white.svg");
      }
    }

    .dotindent {
      position: relative;
      left: 3px;
    }
  }
}



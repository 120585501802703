@import "../lib/mediaquery";
@import "../lib/variable";


.contact-form-thanks {
  &__container {
    border-radius: 12px;
    box-shadow: 0px 0px 60px rgb(0 0 0 / 10%);
    background-color: #fff;
    padding: 64px 24px 64px 24px;
  }

  &__thanks-title {
    font-size: 18px;

    @include pctab {
      text-align: center;
    }

    @include sp {
      text-align: left;
    }
  }

  .button {
    margin-top: 56px;
  }
}